import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Imprint from './views/Imprint.vue'
import Overview from './views/Overview.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: Imprint
    },
    {
      path: '/:canton',
      name: 'overview',
      component: Overview
    }
  ]
})
