import prependHttp from 'prepend-http';
export default {
  parseResponse(response) {
    switch (response) {
      case 'Ja':
      case 'Oui':
      case 'Sì':
      case 'Ja / Oui':
        return { answer: 'ja', score: 3 };
      case 'Eher Ja':
      case 'Plutôt oui':
      case 'Piuttosto sì':
      case 'Eher Ja / Plutôt oui':
        return { answer: 'eherja', score: 2 };
      case 'Eher Nein':
      case 'Piuttosto no':
      case 'Plutôt non':
      case 'Eher Nein / Plutôt non':
        return { answer: 'ehernein', score: 1 };
      case 'Dazu habe ich mir noch keine Gedanken gemacht':
      case "Je n'y ai pas encore pensé.":
      case 'Non ci ho ancora pensato.':
        return { answer: 'keinegedanken', score: 0 };
      default:
        return { answer: 'nein', score: 0 };
    }
  },
  mapResultsFr(results) {
    return results.map((result, index) => {
      const pol = Object.values(result);
      let website = pol[5] ? prependHttp(pol[5], { https: false }) : null;
      return {
        id: index,
        timestamp: pol[0],
        email: pol[1],
        prename: pol[2],
        surname: pol[3],
        website: website,
        party: pol[4],
        canton: pol[6],
        status: pol[7],
        q1: this.parseResponse(pol[9]),
        q2: this.parseResponse(pol[11]),
        q3: this.parseResponse(pol[13]),
        q4: this.parseResponse(pol[15]),
        q5: this.parseResponse(pol[17]),
        q6: this.parseResponse(pol[19]),
        q7: this.parseResponse(pol[21]),
        text: pol[24],
        textq1: pol[10],
        textq2: pol[12],
        textq3: pol[14],
        textq4: pol[16],
        textq5: pol[18],
        textq6: pol[20],
        textq7: pol[22],
        type: pol[8],
        elected: pol[25],
        score: 0,
      };
    });
  },
  mapResultsIt(results) {
    return results.map((result, index) => {
      const pol = Object.values(result);

      let website = pol[5] ? prependHttp(pol[5], { https: false }) : null;
      return {
        id: index,
        timestamp: pol[0],
        email: pol[1],
        prename: pol[2],
        surname: pol[3],
        website: website,
        party: pol[4],
        canton: pol[6],
        status: pol[7],
        q1: this.parseResponse(pol[9]),
        q2: this.parseResponse(pol[11]),
        q3: this.parseResponse(pol[13]),
        q4: this.parseResponse(pol[15]),
        q5: this.parseResponse(pol[17]),
        q6: this.parseResponse(pol[19]),
        q7: this.parseResponse(pol[21]),
        text: pol[23],
        textq1: pol[10],
        textq2: pol[12],
        textq3: pol[14],
        textq4: pol[16],
        textq5: pol[18],
        textq6: pol[20],
        textq7: pol[22],
        type: pol[8],
        elected: 0,
        score: 0,
      };
    });
  },

  mapResultsDe(results) {
    return results.map((result, index) => {
      const pol = Object.values(result);

      let website = pol[5] ? prependHttp(pol[5], { https: false }) : null;
      return {
        id: index,
        timestamp: pol[0],
        email: pol[1],
        prename: pol[2],
        surname: pol[3],
        website: website,
        party: pol[4],
        canton: pol[6],
        status: pol[7],
        q1: this.parseResponse(pol[9]),
        q2: this.parseResponse(pol[11]),
        q3: this.parseResponse(pol[13]),
        q4: this.parseResponse(pol[15]),
        q5: this.parseResponse(pol[19]),
        q6: this.parseResponse(pol[20]),
        q7: this.parseResponse(pol[22]),
        text: pol[23],
        textq1: pol[10],
        textq2: pol[12],
        textq3: pol[14],
        textq4: pol[16],
        textq5: pol[18],
        textq6: pol[24],
        textq7: pol[21],
        type: pol[8],
        elected: pol[25],
        score: 0,
      };
    });
  },
};
