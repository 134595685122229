<template>
  <div :class="{ 'politician': true, 'active': isOpen, 'elected': (data.elected == '1') }"
       v-if="data">
    <a href="#"
       @click.prevent="toggleOpen"
       class="head">
      <span class="num">{{ data.score }}</span>
      <div class="text">
        <span class="name">{{ data.prename }} {{ data.surname }}</span>
        <span class="party"><span v-if="$route.params.canton === 'elected' || $route.params.canton === 'all'"
                class="canton">{{ data.canton }}, </span>{{ data.party }}, {{ parseStatus(data.status) }}<span
                v-if="data.elected === '1'"
                class="elstatus">{{ $t('elected') }}</span><span v-if="data.elected === 'noch offen'"
                class="elstatus elstatus--maybe">{{ $t('electedmaybe') }}</span></span>
      </div>
    </a>
    <div class="body"
         v-if="isOpen">
      <h3 v-if="data.type">{{ $t('type') }}</h3>
      <p v-if="data.type">{{ data.type }}</p>
      <h3 v-if="data.party">{{ $t('party') }}</h3>
      <p v-if="data.party">{{ data.party }}</p>
      <h3 v-if="data.website">Website</h3>
      <a v-if="data.website"
         target="_blank"
         :href="data.website">{{ data.website }}</a>
      <h3>{{ $t('q1title') }}</h3>
      <p class="question"
         v-html="$t('q1')"></p>
      <p class="answer">{{ $t(data.q1.answer) }}</p>
      <p class="qtext"
         v-if="data.textq1">{{ data.textq1 }}</p>
      <h3>{{ $t('q2title') }}</h3>
      <p class="question"
         v-html="$t('q2')"></p>
      <p class="answer">{{ $t(data.q2.answer) }}</p>
      <p class="qtext"
         v-if="data.textq2">{{ data.textq2 }}</p>
      <h3>{{ $t('q3title') }}</h3>
      <p class="question"
         v-html="$t('q3')"></p>
      <p class="answer">{{ $t(data.q3.answer) }}</p>
      <p class="qtext"
         v-if="data.textq3">{{ data.textq3 }}</p>
      <h3>{{ $t('q4title') }}</h3>
      <p class="question"
         v-html="$t('q4')"></p>
      <p class="answer">{{ $t(data.q4.answer) }}</p>
      <p class="qtext"
         v-if="data.textq4">{{ data.textq4 }}</p>
      <h3>{{ $t('q5title') }}</h3>
      <p class="question"
         v-html="$t('q5')"></p>
      <p class="answer">{{ $t(data.q5.answer) }}</p>
      <p class="qtext"
         v-if="data.textq5">{{ data.textq5 }}</p>
      <h3>{{ $t('q6title') }}</h3>
      <p class="question"
         v-html="$t('q6')"></p>
      <p class="answer">{{ $t(data.q6.answer) }}</p>
      <p class="qtext"
         v-if="data.textq6">{{ data.textq6 }}</p>
      <h3>{{ $t('q7title') }}</h3>
      <p class="question"
         v-html="$t('q7')"></p>
      <p class="answer">{{ $t(data.q7.answer) }}</p>
      <p class="qtext"
         v-if="data.textq7">{{ data.textq7 }}</p>
      <h3>8. {{ $t('qtext') }}</h3>
      <p class="qtext">{{ data.text ? data.text : '–' }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Politician',
  props: ['data'],
  data: function () {
    return {
      isOpen: false
    }
  },
  computed: {
  },
  mounted() {
    // console.log(this.data);
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    parseStatus(_status) {
      if (_status === 'Nuovo' || _status === 'Neu' || _status === 'Nouveau') return this.$t('new');
      if (_status === 'Precedente' || _status === 'Précédent' || _status === 'Bisher') return this.$t('previous');
      return _status;
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/helpers';

.politician {
  height: auto;
  border-radius: 2.5rem;
  margin-bottom: 1rem;
}

.head {
  background-color: #fff;
  color: var(--color-dark);
  height: 4.5rem;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid #000;

  .num {
    display: block;
    background-color: var(--color-dark);
    color: #fff;
    font-size: 1.5rem;
    border-radius: 99%;
    border: 6px solid #fff;
    box-sizing: border-box;
    font-weight: normal;
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .text {
    margin-left: 1.5rem;
    width: 100%;
    overflow: hidden;
    padding-right: 1rem;

    .name {
      display: block;
      width: 100%;
      height: auto;
      font-size: 1.5rem;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
    }

    .party {
      font-size: .8rem;
      display: block;
      width: 100%;
      height: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 2rem;

      .elstatus {
        display: inline-block;
        margin-left: 10px;
        background-color: var(--color-red);
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        padding: 0 4px;

        &.elstatus--maybe {
          background-color: #cb5;
        }
      }

      .canton {
        // margin-right: 10px;
        font-weight: bold;
      }
    }
  }


  .elected & {
    border-color: var(--color-red);

    .num {
      background-color: var(--color-red);
    }
  }

  .active & {
    background-color: var(--color-dark);
    color: #fff;

    .num {
      border-color: var(--color-dark);
      color: var(--color-dark);
      background: #fff;
    }
  }


  @include bp-s() {
    height: 4rem;

    .num {
      font-size: 1rem;
      width: 4rem;
      height: 4rem;
    }

    .text {
      margin-left: .5rem;
      position: relative;

      .name {
        font-size: 1rem;
      }
    }
  }
}

.body {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  margin-top: .5rem;
  font-size: .75rem;

  h3 {
    margin: 0;
    max-width: 750px;
    opacity: 1;
    font-weight: 700;
    line-height: 1.3;
    font-size: inherit;
    font-size: 1rem;
  }

  p {
    margin: 0;
    max-width: 700px;

    &.answer,
    &.qtext {
      background-color: rgba(#D4CAB3, .4);
      border-radius: 8px;
      padding: .5rem;
      margin-top: .25rem;
    }
  }

  a+h3,
  p+h3,
  p+p {
    margin-top: 1.5rem;
  }

}
</style>
