import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import helpers from '@/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cantons: [
      'zh',
      'be',
      'lu',
      'ur',
      'sz',
      'ow',
      'nw',
      'gl',
      'zg',
      'fr',
      'so',
      'bs',
      'bl',
      'sh',
      'ar',
      'ai',
      'sg',
      'gr',
      'ag',
      'tg',
      'ti',
      'vd',
      'vs',
      'ne',
      'ge',
      'ju',
    ],
    politicianData: [],
    loading: true,
  },
  mutations: {
    setPoliticianData(state, data) {
      state.politicianData = data;
    },
    loadingComplete(state) {
      state.loading = false;
    },
  },
  actions: {
    async loadData(context) {
      context.commit('setPoliticianData', []);
      let resultsIt = await axios.get(
        'https://opensheet.elk.sh/19CHHpsjUwcg0M5XSlWMohbFkSd8zY4jmDKlwhhBpOrA/1'
      );
      let resultsFr = await axios.get(
        'https://opensheet.elk.sh/19CHHpsjUwcg0M5XSlWMohbFkSd8zY4jmDKlwhhBpOrA/2'
      );
      let resultsDe = await axios.get(
        'https://opensheet.elk.sh/19CHHpsjUwcg0M5XSlWMohbFkSd8zY4jmDKlwhhBpOrA/3'
      );
      context.commit('loadingComplete');

      resultsDe = helpers.mapResultsDe(resultsDe.data);
      resultsFr = helpers.mapResultsFr(resultsFr.data);
      resultsIt = helpers.mapResultsIt(resultsIt.data);

      const allResults = resultsIt.concat(resultsDe, resultsFr);
      allResults.forEach((pol) => {
        pol.score =
          pol.q1.score +
          pol.q2.score +
          pol.q3.score +
          pol.q4.score +
          pol.q5.score +
          pol.q6.score +
          pol.q7.score;
      });
      allResults.sort((a, b) => {
        return b.score - a.score;
      });

      context.commit('setPoliticianData', allResults);
      context.commit('loadingComplete');
    },
  },
  getters: {
    cantons: (state) => {
      return state.cantons;
    },
    politicianData: (state) => {
      return state.politicianData;
    },
    loading: (state) => {
      return state.loading;
    },
  },
});
