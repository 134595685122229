<template>
  <div class="overview">
    <div class="overview__cantons">
      <router-link :class="{ 'overview__cantonlink': true, 'overview__cantonlink--all': true, 'active': isActiveCanton('all') }"
                   :to="'/all'">{{ $t('allcantons') }}</router-link>
      <router-link :class="{ 'overview__cantonlink': true, 'overview__cantonlink--all': true, 'active': isActiveCanton('elected') }"
                   :to="'/elected'">{{ $t('elected') }}</router-link>
      <router-link v-for="(canton, index) in cantons"
                   :class="{ 'overview__cantonlink': true, 'active': isActiveCanton(canton) }"
                   :key="index"
                   :to="'/' + canton">{{ canton }}</router-link>
    </div>
    <div class="overview__politicians"
         v-if="politicianData">
      <Politician v-for="(politician, index) in filteredPoliticians"
                  :key="index"
                  :data="politician" />
    </div>
    <div class="overview__msg"
         v-if="filteredPoliticians.length < 1">
      {{ $t('noData') }}
    </div>
    <!-- <p>{{ $t('comingSoon') }}</p> -->
  </div>
</template>

<script>
import Politician from '@/components/Politician';

export default {
  name: 'overview',
  props: ['canton'],
  components: {
    Politician
  },
  mounted() {
  },
  computed: {
    cantons() {
      return this.$store.getters.cantons;
    },
    activeCanton() {
      return this.$route.params.canton;
    },
    politicianData() {
      return this.$store.getters.politicianData;
    },
    filteredPoliticians() {
      // return [];
      if (this.activeCanton == 'all') return this.politicianData;
      if (this.activeCanton == 'elected') {
        return this.politicianData.filter((pol) => {
          return pol.elected === "1";
        });
      }
      return this.politicianData.filter((pol) => {
        if (!pol.canton) return false;
        return pol.canton.toLowerCase() == this.activeCanton.toLowerCase();
      });
    }
  },
  methods: {
    isActiveCanton(canton) {
      return canton === this.activeCanton;
    }
  }
}

</script>

<style lang="scss">
.overview {
  margin: 1rem auto 2rem auto;
  padding: 0 1rem;
  width: 100%;
  max-width: var(--site-width);
  box-sizing: border-box;
  flex-grow: 1;

  &__cantons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 2rem;
  }

  &__cantonlink {
    display: block;
    padding: 0 1rem;
    background: #fff;
    margin: .25rem;
    text-transform: uppercase;
    text-decoration: none;
    height: 2rem;
    line-height: 2rem;
    border-radius: 1rem;
    border: 1px solid #000;

    &.active {
      background: var(--color-dark);
      color: #fff;
    }

    &:hover {
      color: var(--color-highlight);
    }

    &--all {
      text-transform: uppercase;
    }
  }

  &__politicians {
    margin: 2rem 0;
  }
}
</style>