<template>
  <div class="imprint">
    <h1>{{ $t('imprint') }}</h1>
    <p v-html="$t('address')"></p>
    <p v-html="$t('projectgroup')"></p>
    <p v-html="$t('contact')"></p>
  </div>
</template>

<script>

export default {
  name: 'imprint',
}

</script>

<style lang="scss">
.imprint {
  margin: 1rem auto 2rem auto;
  padding: 0 1rem;
  width: 100%;
  max-width: var(--site-width);
  box-sizing: border-box;
  flex-grow: 1;

  p {
    width: 100%;
    text-align: left;
  }
}
</style>