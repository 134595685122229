<template>
  <div class="home">
    <div class="lead"
         v-html="$t('intro')"></div>
    <div class="home__cantons">
      <router-link class="home__cantonlink home__cantonlink--all"
                   :to="'/all'">{{ $t('allcantons') }}</router-link>
      <router-link class="home__cantonlink home__cantonlink--all"
                   :to="'/elected'">{{ $t('elected') }}</router-link>
      <router-link v-for="(canton, index) in cantons"
                   class="home__cantonlink"
                   :key="index"
                   :to="'/' + canton">{{ canton }}</router-link>
    </div>
    <div class="sponsors">
      <p v-html="$t('taskforce')"></p>
      <p>{{ $t('supportedby') }}</p>
      <ul class="sponsors__list">
        <li><a target="_blank"
             href="https://www.a-d-s.ch">A*dS - Autorinnen und Autoren der Schweiz</a></li>
        <li><a target="_blank"
             href="https://www.alesi.ch">ALESI - Associazione Librai e Editori della Svizzera italiana</a></li>
        <li><a target="_blank"
             href="https://www.bibliosuisse.ch">Bibliosuisse</a></li>
        <li><a target="_blank"
             href="https://www.cras-romandie.ch/">CRAS - Coordination Romande Des Arts de la Scène</a></li>
        <li><a target="_blank"
             href="https://www.dansesuisse.ch">Danse Suisse</a></li>
        <li><a target="_blank"
             href="https://www.evta.ch/">EVTA - Schweizer Verband der Gesangslehrenden</a></li>
        <li><a target="_blank"
             href="https://swissanimation.ch/">GSFA - Film d'animation Suisse I Animationsfilm Schweiz</a></li>
        <li><a target="_blank"
             href="https://www.kultur-vermittlung.ch">Kulturvermittlung Schweiz</a></li>
        <li><a target="_blank"
             href="https://www.orchester.ch">Orchester Schweiz</a></li>
        <li><a target="_blank"
             href="https://www.procinema.ch">Pro Cinema</a></li>
        <li><a target="_blank"
             href="https://www.theaterschweiz.ch">Schweizerischer Bühnenverband</a></li>
        <li><a target="_blank"
             href="https://www.interpreten.ch">SIG - Schweizer Interpretengenossenschaft</a></li>
        <li><a target="_blank"
             href="https://www.smpv.ch">SMPV - Schweizerischer Musikpädagogoischer Verband</a></li>
        <li><a target="_blank"
             href="https://www.musikrat.ch">SMR - Schweizer Musikrat</a></li>
        <li><a target="_blank"
             href="https://www.sonart.swiss">SONART - Musikschaffende Schweiz</a></li>
        <li><a target="_blank"
             href="https://www.suisseculture.ch">Suisseculture</a></li>
        <li><a target="_blank"
             href="https://www.swiss-design-association.ch">Swiss Design Association</a></li>
        <li><a target="_blank"
             href="https://www.szeneschweiz.ch">SzeneSchweiz</a></li>
        <li><a target="_blank"
             href="https://www.tpunkt.ch">t. Theaterschaffende Schweiz</a></li>
        <li><a target="_blank"
             href="https://www.visarte.ch">VISARTE</a></li>
        <li><a target="_blank"
             href="https://www.visarte-basel.ch">Visarte Basel</a></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home',
  computed: {
    cantons() {
      return this.$store.getters.cantons;
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/helpers';

.home {
  width: 100%;
  max-width: 1100px;
  margin: 2rem auto;
  padding: 0 1rem;
  box-sizing: border-box;
  flex-grow: 1;

  .lead {
    text-align: left;
    max-width: var(--site-width);
    line-height: 1.4;
  }

  &__cantons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &__cantonlink {
    display: block;
    width: calc(33.3% - .66rem);
    font-family: 'Montserrat', sans-serif;
    color: var(--color-dark);
    text-transform: uppercase;
    font-size: 2.25rem;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 2rem;
    height: 4rem;
    line-height: 4rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    // filter: drop-shadow(0 4px 5px rgba(0, 0, 0, .05));

    &:hover {
      background-color: var(--color-highlight);
    }

    @include bp-s() {
      width: calc(50% - .5rem);
    }

    &--all {
      font-size: 1rem;
      letter-spacing: 1px;

      @include bp-s() {
        font-size: .75rem;
      }
    }
  }

  &__logos {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    a {
      margin: 0 2rem;
    }

    img {
      height: 150px;
    }

    @include bp-s() {
      a {
        margin: 0 1rem;
      }

      img {
        height: 80px;
      }
    }
  }
}

.sponsors {
  margin-top: 5rem;
  margin-bottom: -2rem;

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      display: block;
      margin-bottom: 8px;
    }

    a {
      display: block;
      text-decoration: none;
      border: 1px solid var(--color-highlight);
      border-radius: 1rem;
      padding: .5rem 1rem;
      box-sizing: border-box;
      text-align: left;
    }
  }
}
</style>
