import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

let locale = 'de';
let userLang = navigator.language.split('-')[0];
if (userLang == 'fr') locale = 'fr';
if (userLang == 'it') locale = 'it';

export default new VueI18n({
  locale: locale,
  messages: {
    de: {
      noData: 'Aus diesem Kanton sind leider keine Daten vorhanden.',
      logo: '/images/mp-logo-de.svg',
      siteName: 'musik<br>&politik',
      comingSoon:
        'Die Umfrageergebnisse werden spätestens am 18.09.23 publiziert!',
      intro:
        '<p>Herzlich willkommen bei Clap4Culture.ch!</p><p>Die Anliegen der Kultur gehören auch ins Parlament! Wir haben allen Kandidierenden der nationalen Wahlen 2023 eine Umfrage geschickt mit 8 Fragen zu den wichtigsten Kultur-Themen, die in der nächsten Legislatur eine Rolle spielen könnten.</p><p>Finden Sie heraus, welche Kandidierenden sich in Ihrem Kanton für unsere Anliegen einsetzen. Wir haben die Antworten nach einem Punktesystem gewertet. Die maximale Punktezahl ist 21, wenn jemand uns in allen Themen unterstützen will. Die erreichte Punktzahl findet sich jeweils direkt vor dem Namen der Kandidierenden. Mit einem Klick auf den Namen sehen Sie die konkreten Antworten und Kommentare der Kandidierenden.</p><p>Vielen Dank, dass Sie mit Ihrem Wahlzettel mitgeholfen haben, die Position der Kultur im Parlament zu stärken!</p>',
      taskforce:
        '<p><a href="https://www.taskforceculture.ch" target="_blank">taskforceculture.ch</a></p><p>Dieses Projekt wird von der Taskforce koordiniert, wobei einzelne zugehörige Organisationen es nicht aktiv unterstützen. Die Taskforce Culture ist eine informelle Arbeitsgruppe, zusammengesetzt aus Vertretungen verschiedener Kulturverbände und -organisationen. Sie funktioniert verbands- und spartenübergreifend und entspricht einem grossen Bedürfnis nach Austausch und gemeinsamer Intervention.</p>',
      party: 'Partei',
      canton: 'Kanton',
      q1: 'Kulturschaffende verdienen ihr Geld oft mit einer gemischten Berufstätigkeit. Sie sind also häufig teilweise selbstständig erwerbend, in Kleinstunternehmen tätig oder teilweise angestellt (Festanstellung und/oder kurze befristete Arbeitsverhältnisse). Unser Sozialversicherungsrecht weist viele Lücken auf, die nicht nur, aber auch Kulturschaffende und ihre atypischen Erwerbsformen benachteiligen und durch die Maschen fallen lassen. Durch die Corona-Krise wurden diese Problematiken akzentuiert und für alle sichtbar gemacht.<br><strong>Werden Sie Vorstösse unterstützen, die solche Lücken schliessen und Menschen in Kulturberufen besser absichern?</strong>',
      q1title: '1. Sozialpolitik',
      q2: 'Kulturförderung ermöglicht ein breites und vielseitiges Angebot in der Schweiz. Hier stellen sich durch Kleinräumigkeit und Mehrsprachigkeit besondere Herausforderungen.<br><strong>Werden Sie im Parlament eine starke und an den Bedürfnissen der Sparten orientierte Kulturförderung verteidigen, auch wenn Spardruck besteht?</strong>',
      q2title: '2. Kulturförderung',
      q3: 'Das Urheberrecht beruht auf folgendem Grundsatz: Urheberinnen und Urhebern steht eine angemessene Beteiligung zu, wenn ihre Werke gewinnbringend von Dritten genutzt werden. Für viele Kulturschaffende sind diese Tantiemen eine wichtige Einnahmequelle. <br><strong>Werden Sie im Parlament ein wirkungsvolles und angemessenes Urheberrecht verteidigen und weiterentwickeln, gerade auch im Bereich der neuen digitalen Nutzungsformen?</strong>',
      q3title: '3. Urheberrecht',
      q4: 'Sichtbarkeit und Hörbarkeit sind für das Kulturschaffen und seine Wahrnehmung enorm wichtig. Mediale Präsenz hat einerseits einen Werbeeffekt zugunsten der Werke und sie bereichert andererseits den Alltag durch den gesellschaftlichen Austausch zu kulturellen Werken und ihren Themen. Die Kulturberichterstattung leidet stark unter der zunehmenden Konzentration des Medienplatzes Schweiz. <br><strong>Befürworten und unterstützen Sie konkrete Rahmenbedingungen, die eine breite und vielseitige Sichtbarkeit des Schweizer Kulturschaffens in den Medien verbessern?</strong>',
      q4title: '4. Medienpolitik',
      q5: 'Internationale digitale Plattformen sind zu den wichtigsten Vertriebs- und Publikationskanälen für kulturelle Werke geworden. Sie machen hohe Umsätze in der Schweiz, haben aber kaum Verantwortung gegenüber den Kreativen und Konsumierenden vor Ort. <br><strong>Werden Sie Vorstösse unterstützen zur Stärkung der Position von einheimischen Kulturschaffenden und Rechteinhabern gegenüber den internationalen Plattformen?</strong>',
      q5title: '5. Digitalpolitik',
      q6: 'Ein lebendiger und relevanter Kulturbetrieb, der auch international wahrgenommen wird, löst eine hohe Wertschöpfung in den Bereichen Gastronomie, Hotellerie usw. aus. Zudem sind kulturelle Anlässe wichtige Stützen des Standortmarketings. Das wirtschaftliche Volumen der Kulturbranche wurde auch während der Pandemie sichtbar. <br><strong>Werden Sie die Anliegen der Kulturwirtschaft auch in wirtschaftspolitische Überlegungen verstärkt mit einbeziehen?</strong>',
      q6title: '6. Kultur als Wirtschaftsfaktor',
      q7: 'Die Kultur schafft Zusammenhalt sowohl im professionellen wie auch im Amateurbereich. Sie bietet eine Plattform für den aktiven Austausch, sie ist ein Kitt für die Gesellschaft. Die Kultur durchdringt auch Bereiche wie Bildung, Brauchtum, Gesundheit, Wirtschaft und Integration. Zudem kommt der Kultur auch eine Gedächtnisfunktion zu. Daher sollte die Kultur auch in Diskussionen zu Bildung, Gesellschaft, Sozialem und Ökonomie eine Rolle spielen. <br><strong>Teilen Sie diese Auffassung und wird das Bewusstsein dafür auch Ihre politische Arbeit mitprägen?</strong>',
      q7title: '7. Kultur im Alltag',
      qtext:
        'Was ist Ihr persönlicher Bezug zur Kultur? Beschäftigen Sie sich selbst mit künstlerischen/kulturellen Aktivitäten?',
      nein: 'Nein',
      eherja: 'Eher Ja',
      ehernein: 'Eher Nein',
      ja: 'Ja',
      keinegedanken: 'Dazu habe ich mir noch keine Gedanken gemacht',
      allcantons: 'Alle Kantone',
      type: 'Nationalrat / Ständerat',
      projectby: 'Ein Projekt von:',
      supportedby: 'Unterstützt von:',
      smvlogo: '/images/logos/smv.png',
      elected: 'gewählt',
      electedmaybe: 'noch offen',
      imprint: 'Impressum',
      address:
        'Taskforce Culture<br>Projekt Wahlumfrage<br>c/o Suisseculture<br>Kasernenstrasse 23<br>8004 Zürich',
      projectgroup:
        '<strong>Projektgruppe:</strong><br>Sandra Tinner, Geschäftsleiterin Schweizer Musikrat<br>Christoph Trummer, Präsident Schweizer Interpretengenossenschaft<br>Cyril Tissot, Geschäftsleiter Danse Suisse',
      contact:
        '<a href="https://www.taskforceculture.ch" target="_blank">www.taskforceculture.ch</a><br>Kontakt: <a href="mailto:election23@interpreten.ch">election23@interpreten.ch</a>',
      new: 'neu',
      previous: 'bisher',
    },
    fr: {
      noData:
        "Malheureusement, il n'y a pas de données disponibles pour ce canton.",
      logo: '/images/mp-logo-fr.svg',
      siteName: 'musique<br>&politique',
      comingSoon:
        'Les résultats du sondage seront publiés au plus tard le 18.09.23.',
      intro:
        "<p>Bienvenue sur Clap4Culture.ch</p><p>Les intérêts de la culture doivent aussi être défendus au Parlement ! Nous avons envoyé à tou·tes les candidat·es aux élections nationales de 2023 un sondage comportant huit questions sur les principaux thèmes culturels qui pourraient jouer un rôle dans la prochaine législature.</p><p>Découvrez quel·les candidat·es de votre canton s'engagent pour nos intérêts. Nous avons évalué les réponses selon un système de points. Le nombre maximum de points est de 21 si quelqu'un veut nous soutenir sur tous les thèmes. Le nombre de points obtenus se trouve directement devant le nom de la candidate/du candidat. En cliquant sur son nom, vous pouvez voir les réponses concrètes et les commentaires des candidat·es.</p><p>Merci d'avoir contribué, par votre bulletin de vote, à renforcer la position de la culture au Parlement&nbsp;!</p>",
      taskforce:
        '<a href="https://www.taskforceculture.ch" target="_blank">www.taskforceculture.ch</a><p>Ce projet est coordonné par la Taskforce Culture, étant entendu que certaines organisations associées ne le soutiennent pas activement.</p><p>La Taskforce Culture est un groupe de travail informel, composé de représentant·es de différentes associations et organisations culturelles. Elle fonctionne de manière interassociative et intersectorielle et répond à un grand besoin d\'échange et d\'intervention commune.</p>',
      party: 'Partie',
      canton: 'Canton',
      q1: "Les professionnel·les de la culture gagnent souvent leur vie en exerçant une activité professionnelle mixte. Ils et elles sont donc souvent partiellement indépendants, actifs dans des micro-entreprises ou partiellement employés (emploi fixe et/ou contrat de travail à durée déterminée de courte durée). Notre droit des assurances sociales présente de nombreuses lacunes qui pénalisent, parmi d’autres, les acteurs culturels et leurs formes d'activité atypiques, et qui les font passer entre les mailles du filet. La crise pandémique a accentué ces problèmes et les a rendus visibles pour tous.<br><strong>Soutiendrez-vous des interventions visant à combler de telles lacunes et à mieux protéger les personnes exerçant des professions culturelles ?</strong>",
      q1title: '1.  Politique sociale',
      q2: "L’encouragement de la culture permet de proposer une offre large et variée en Suisse. L'exiguïté du territoire et le plurilinguisme posent ici des défis particuliers.<br><strong>Défendrez-vous au Parlement un encouragement de la culture fort et orienté vers les besoins des disciplines, même sous la pression budgétaire ?</strong>",
      q2title: '2. Encouragement de la culture',
      q3: "Le droit d'auteur repose sur le principe suivant : les auteurs et autrices ont droit à une participation équitable lorsque leurs œuvres sont utilisées avec profit par des tiers. Pour de nombreux créateurs culturels, ces droits constituent une source de revenus importante.<br><strong>Défendrez-vous et développerez-vous au Parlement un droit d'auteur efficace et approprié, notamment dans le domaine des nouvelles formes d'utilisation numérique ?</strong>",
      q3title: "3. Droit d'auteur",
      q4: "La visibilité et l'audibilité sont extrêmement importantes pour la création culturelle et sa perception. La présence médiatique a, d'une part, un effet promotionnel en faveur des œuvres et, d'autre part, elle enrichit le quotidien grâce aux échanges sociaux sur les œuvres culturelles et leurs thèmes. La couverture médiatique de la culture souffre fortement de la concentration croissante de la place médiatique suisse.<br><strong>Approuvez-vous et soutenez-vous des conditions-cadres concrètes qui améliorent la visibilité large et diversifiée de la création culturelle suisse dans les médias ?</strong>",
      q4title: '4. Politique des médias',
      q5: "Les plateformes numériques internationales sont devenues les principaux canaux de distribution et de diffusion des œuvres culturelles. Elles réalisent des chiffres d'affaires élevés en Suisse, mais n'ont guère de responsabilité vis-à-vis des créateurs et des consommateurs sur place.<br><strong>Soutiendrez-vous des interventions visant à renforcer la position des créateurs et créatrices culturel·les et des ayant-droits nationaux face aux plateformes internationales ?</strong>",
      q5title: '5. Politique numérique',
      q6: "Une activité culturelle vivante et pertinente, qui est également perçue au niveau international, génère une forte création de valeur dans les domaines de la gastronomie, de l'hôtellerie, etc. De plus, les événements culturels sont des piliers importants de marketing régional. Le volume économique du secteur culturel a également été visible pendant la pandémie.<br><strong>Intégrerez-vous davantage les préoccupations des industries culturelles dans les réflexions de politique économique ?</strong>",
      q6title: '6. La culture comme facteur économique',
      q7: "La culture crée une cohésion tant dans le domaine professionnel que dans le domaine amateur. Elle offre une plateforme d'échanges actifs, elle est un ciment pour la société. La culture imprègne également des domaines tels que l'éducation, les coutumes, la santé, l'économie et l'intégration. En outre, la culture a aussi une fonction de mémoire. C'est pourquoi la culture devrait également jouer un rôle dans les discussions sur l'éducation, la société, le social et l'économie.<br><strong>Partagez-vous ce point de vue et la prise de conscience de cet état de fait influencera-t-elle également votre travail politique ?</strong>",
      q7title: '7. La culture au quotidien',
      qtext:
        'Quel est votre rapport personnel à la culture ? Pratiquez-vous personnellement des activités artistiques / culturelles ?',
      nein: 'Non',
      ehernein: 'Plutôt non',
      eherja: 'Plutôt oui',
      ja: 'Oui',
      keinegedanken: "Je n'y ai pas encore pensé.",
      allcantons: 'Tous les cantons',
      type: 'Conseil national / Conseil des États',
      projectby: 'Un projet de:',
      supportedby: 'Organisations soutenant le projet:',
      smvlogo: '/images/logos/smv-fr.png',
      elected: 'élu',
      electedmaybe: 'peut-être élu',
      imprint: 'Mentions légales',
      address:
        'Taskforce Culture<br>Projet Sondage électoral<br>c/o Suisseculture<br>Kasernenstrasse 23<br>8004 Zürich',
      projectgroup:
        '<strong>Groupe de projet:</strong><br>Sandra Tinner, directrice du Conseil suisse de la musique<br>Christoph Trummer, président de la Coopérative suisse des artistes interprètes<br>Cyril Tissot, directeur de Danse Suisse',
      contact:
        '<a href="https://www.taskforceculture.ch" target="_blank">www.taskforceculture.ch</a><br>Contact: <a href="mailto:election23@interpreten.ch">election23@interpreten.ch</a>',
      new: 'nouvelle / nouveau',
      previous: 'sortant·e',
    },
    it: {
      logo: '/images/mp-logo-it.svg',
      siteName: 'musique<br>&politique',
      intro:
        '<p>Benvenuti su Clap4Culture.ch</p><p>I bisogni della cultura devono essere difesi anche in Parlamento! Abbiamo inviato a tutti i candidati e le candidate alle elezioni federali 2023 un sondaggio con 9 domande sui principali temi culturali che potrebbero avere un ruolo rilevante nella prossima legislatura.</p><p>Scoprite quali candidati e candidate del vostro cantone si impegnano a favore dei nostri interessi. Abbiamo valutato le risposte secondo un sistema di punteggio: quando qualcuno sostiene tutti i nostri temi il punteggio massimo è 21 punti. Il punteggio appare direttamente davanti al nome di ogni candidato-a, cliccando sul nome è possibile vedere le risposte e i commenti specifici dei-lle candidati-e.</p><p>Vi ringraziamo se con il vostro voto avete rafforzato la posizione della cultura in Parlamento!</p>',
      taskforce:
        '<p><a href="https://www.taskforceculture.ch" target="_blank">taskforceculture.ch</a></p><p>Questo progetto è coordinato dalla Taskforce Culture anche se non tutte le singole organizzazioni associate lo sostengono attivamente. La Taskforce Culture è un gruppo di lavoro informale composto da rappresentanti di varie associazioni e organizzazioni culturali. Funziona in modo trasversale alle associazioni e ai settori e risponde a un grande bisogno di scambio e intervento congiunto.</p>',
      party: 'Partito',
      canton: 'Cantone',
      q1: 'Chi opera nell’ambito della cultura spesso si guadagna da vivere con un mix di attività. Non di rado, infatti, si tratta di professionisti parzialmente indipendenti, che lavorano in micro-imprese o sono assunti a tempo parziale (impiego fisso e/o contratti di lavoro brevi a tempo determinato). Il nostro diritto delle assicurazioni sociali presenta molte lacune che penalizzano e trascurano non solo, ma anche le operatrici e gli operatori culturali e le loro forme lavorative atipiche. La crisi scatenata dalla pandemia non ha fatto che accentuare queste problematiche, mettendole sotto gli occhi di tutti. Sosterrete le iniziative promosse al fine di colmare queste lacune e tutelare meglio chi esercita una professione culturale? ',
      q1title: '1. Politica sociale',
      q2: 'La promozione della cultura consente di avere in Svizzera un’offerta ampia e variegata. Il multilinguismo e gli spazi ristretti che caratterizzano il nostro Paese, tuttavia, pongono una serie di sfide particolari.<br><strong>Difenderete in Parlamento una politica di promozione della cultura forte e orientata alle esigenze dei diversi comparti, anche in caso di pressione al risparmio?</strong>',
      q2title: '2. Promozione della cultura',
      q3: 'Il diritto d’autore si fonda sul seguente principio: le autrici e gli autori hanno diritto a una congrua partecipazione se le loro opere vengono utilizzate da terzi a scopo di lucro. Per molte operatrici e molti operatori culturali queste royalty rappresentano un’importante fonte di reddito.<br><strong>Difenderete e perfezionerete in Parlamento un diritto d’autore congruo ed efficace, soprattutto anche nel contesto delle nuove forme di fruizione digitali?</strong>',
      q3title: '3. Diritto d’autore',
      q4: 'Visibilità e udibilità sono di enorme importanza per la produzione culturale e la sua percezione. La presenza sui media, da un lato, produce un effetto pubblicitario a favore delle opere e, dall’altro, arricchisce la quotidianità attraverso lo scambio sociale sulle opere culturali e le loro tematiche. L’informazione culturale sta risentendo fortemente della crescente concentrazione del panorama mediatico svizzero.<br><strong>Approverete e sosterrete la definizione di un quadro di riferimento concreto, che favorisca un’ampia e variegata visibilità della produzione culturale svizzera nei media?</strong>',
      q4title: '4. Politica dei media',
      q5: 'Le piattaforme digitali internazionali sono diventate i principali canali di distribuzione e pubblicazione per le opere culturali. Pur realizzando ingenti fatturati in Svizzera, esse non hanno pressoché alcuna responsabilità nei confronti dei creativi e dei consumatori locali.<br><strong>Sosterrete le iniziative volte a rafforzare la posizione di chi fa musica e detiene i diritti a livello locale nei confronti delle piattaforme internazionali.</strong>',
      q5title: '5. Politica digitale',
      q6: 'Un’attività culturale vivace e rilevante, che sia percepita anche a livello internazionale, genera un forte valore aggiunto nell’ambito della ristorazione, del settore alberghiero ecc. Gli eventi culturali, inoltre, sono pilastri importanti del marketing territoriale. Il volume economico rappresentato dal settore della cultura è diventato tangibile anche nel corso della pandemia.<br><strong>Darete maggiore peso alle esigenze del comparto culturale anche nelle riflessioni in materia di politica economica?</strong>',
      q6title: '6. Cultura come fattore economico',
      q7: 'La cultura crea coesione in ambito sia professionale che amatoriale. Offre una piattaforma per lo scambio attivo, è un collante per la società. La cultura permea anche settori come l’istruzione, il folclore, la sanità, l’economia e l’integrazione. Svolge altresì una funzione di memoria. La cultura dovrebbe pertanto avere un ruolo anche nelle discussioni in materia di istruzione, società, affari sociali ed economia.<br><strong>Condividete questo punto di vista e farete in modo che questa consapevolezza orienti anche la vostra attività politica?</strong>',
      q7title: '7. Cultura nella quotidianità',
      qtext:
        'Qual è il vostro rapporto personale con la cultura? Siete impegnati personalmente in attività artistiche/culturali?',
      nein: 'No',
      ehernein: 'Piuttosto no',
      eherja: 'Piuttosto sì',
      ja: 'Sì',
      keinegedanken: 'Non ci ho ancora pensato.',
      allcantons: 'Tutti i cantoni',
      type: 'Consiglio nazionale / Consiglio degli Stati',
      projectby: 'Un progetto di:',
      supportedby: 'Organizzazioni sostenitrici:',
      smvlogo: '/images/logos/smv-fr.png',
      elected: 'eletto',
      electedmaybe: 'forse eletto',
      imprint: 'Informazioni legali',
      address:
        'Taskforce Culture<br>Progetto di sondaggio elettorale<br>c/o Suisseculture<br>Kasernenstrasse 23<br>8004 Zürich',
      projectgroup:
        '<strong>Gruppo di progetto:</strong><br>Sandra Tinner, direttrice del Consiglio svizzero della musica<br>Christoph Trummer, presidente della Cooperativa svizzera degli artisti interpreti<br>Cyril Tissot, direttore di Danse Suisse',
      contact:
        '<a href="https://www.taskforceculture.ch" target="_blank">www.taskforceculture.ch</a><br>Contatto: <a href="mailto:election23@interpreten.ch">election23@interpreten.ch</a>',
      new: 'nuova / nuovo',
      previous: 'precedente',
    },
  },
});
