<template>
  <div id="app">
    <header class="header">
      <div class="header__inner">
        <ul class="lang">
          <li v-for="(lang, index) in langs"
              :key="index"><a href="#"
               :class="{ active: $i18n.locale === lang }"
               @click.prevent="setLang(lang)">{{ lang }}</a></li>
        </ul>
        <router-link class="logo"
                     to="/"><img :src="$t('logo')"></router-link>
      </div>
    </header>
    <div v-if="loading"
         class="spinner-wrap">
      <span class="spinner"></span>
    </div>
    <router-view v-if="!loading" />
    <footer class="footer">
      <div class="footer__inner">
        <router-link to="/imprint">{{ $t('imprint') }}</router-link>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  mounted() {
    this.$store.dispatch('loadData');
  },
  data: function () {
    return {
      langs: ['de', 'fr', 'it']
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    }
  },
  methods: {
    setLang(lang) {
      this.$i18n.locale = lang;
    }
  }
}

</script>

<style lang="scss">
@import './assets/scss/global.scss';

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.header {
  color: var(--color-dark);
  background-color: rgba(#D4CAB3, .2);
  text-align: center;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  position: relative;
  flex-grow: 0;

  &__inner {
    padding: 2rem 1rem 1rem 1rem;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
  }

  .logo {
    img {
      max-height: 8rem;

      @include bp-s() {
        max-height: 5.5rem;
        margin-top: 1rem;
      }
    }
  }
}

ul.lang {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 2rem;
  right: 1rem;

  li {
    display: inline-block;

    &::after {
      content: "/";
      margin: 0 .5rem;
    }

    &:last-child::after {
      content: "";
      margin: 0;
    }

    a {
      text-decoration: none;

      &.active {
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}

.footer {
  text-align: left;
  background-color: rgba(#D4CAB3, .2);
  margin-top: 6rem;
  flex-grow: 0;

  &__inner {
    max-width: var(--site-width);
    margin: 0 auto;
    padding: 2rem 1rem;
    box-sizing: border-box;
    text-align: center;
  }
}

.spinner-wrap {
  position: relative;
  max-width: 800px;
  margin: 2rem auto 10rem auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.spinner {
  display: block;
  width: 2rem;
  height: 2rem;
  background: transparent;
  position: absolute;
  top: 0;
  left: 1rem;
  // margin-left: -1rem;
  border-radius: 100%;
  border: 4px solid var(--color-highlight);
  box-sizing: border-box;
  border-top-color: rgba(#D4CAB3, .75);
  border-left-color: rgba(#D4CAB3, .3);
  border-bottom-color: rgba(#D4CAB3, .1);
  animation: 2s loadingspin linear infinite;
}

@keyframes loadingspin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
